export const handleError = (context, error) => {
  // console.log("error handled");
  if (error.response.status == 401) {
    alert("Anda tidak memliki akses untuk halaman ini");
  }
  if (error.response.status == 404) {
    alert("Perubahan gagal, data kemungkinan tidak ada");
  }
};

export const handleOk = (router, response, message = "Proses sukses") => {
  console.log("error handled");
  if (response.status == 200) {
    alert(message);
    router.go(-1);
  }
};

export const handleCreated = (router, response) => {
  if (response.status == 201) {
    alert("Data sukses ditambahkan");
    router.go(-1);
  }
};
