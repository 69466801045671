import { ref, onMounted } from "vue";
import { handleError } from "@/utils/errorHandler";
import ProviderService from "@/services/provider_services";
import Pagination from "@/components/PaginationTable.vue";

export default {
  name: "Provider Admin",
  components: { Pagination },
  setup(props, context) {
    const inputData = ref({
      id: undefined,
      provider: undefined,
      picture: undefined,
    });

    const editButtonStatus = ref(false);

    const tableData = ref([{ id: 0, provider: "", picture: "" }]);

    const pagination = ref({
      page: 1,
      limit: 40,
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
    });

    const updatePage = (value) => {
      pagination.value.page = value;
      fetchData();
    };

    const fetchData = async () => {
      try {
        let response = await ProviderService.getAll(
          pagination.value.page,
          pagination.value.limit
        );
        tableData.value = response.data.data.rows;
        pagination.value.firstPage = response.data.data.firstPage;
        pagination.value.lastPage = response.data.data.lastPage;
        pagination.value.currentPage = response.data.data.currentPage;
      } catch (error) {
        handleError(context, error);
      }
    };

    onMounted(async () => {
      fetchData();
    });

    const doEdit = (data) => {
      inputData.value = { ...data };
      editButtonStatus.value = true;
    };

    const cancelEdit = () => {
      inputData.value.id = undefined;
      editButtonStatus.value = false;
    };

    const doDelete = async (data) => {
      let confirmation = confirm(
        `Yakin menghapus data ${data.provider}?\nData yang dihapus tidak dapat dikembalikan`
      );
      if (confirmation) {
        try {
          let response = await ProviderService.deleteData(data.id);
          if (response.data.success) {
            fetchData();
            alert("Data berhasil dihapus");
          }
        } catch (error) {
          handleError(context, error);
        }
      }
    };

    const doSave = () => {
      let confirmation = confirm(`Yakin menyimpan data ?`);
      if (confirmation) {
        if (inputData.value.id) {
          updateExistingData();
        } else {
          createNewData();
        }
        cancelEdit();
        fetchData();
      }
    };

    const createNewData = async () => {
      try {
        let response = await ProviderService.createData(inputData.value);
        if (response.data.success) {
          fetchData();
          alert("Data berhasil disimpan");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    const updateExistingData = async () => {
      try {
        let response = await ProviderService.updateData(
          inputData.value.id,
          inputData.value
        );
        if (response.data.success) {
          fetchData();
          alert("Data berhasil diubah");
        }
      } catch (error) {
        handleError(context, error);
      }
    };

    return {
      inputData,
      tableData,
      pagination,
      editButtonStatus,
      updatePage,
      doEdit,
      cancelEdit,
      doDelete,
      doSave,

    };
  },
};
