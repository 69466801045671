<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Pengaturan Provider Game</h3>
  <div class="columns is-multiline text-left mb-6">
    <div class="column is-12">
      <div class="field">
        <label class="label">Id Provider</label>
        <div class="control">
          <input class="input" type="text" v-model="inputData.id" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">Nama Provider</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Nama Provider"
            v-model="inputData.provider"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Gambar</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Gambar"
            v-model="inputData.icon"
          />
        </div>
        <p class="help">Disarankan menggunakan cdn agar loading cepat</p>
      </div>
    </div>
    <div class="column">
      <button
        class="button is-danger mr-1"
        @click="cancelEdit()"
        v-bind:disabled="!editButtonStatus"
      >
        Batal Edit
      </button>
      <button class="button is-success" @click="doSave()">Simpan Data</button>
    </div>
  </div>

  <div class="mt-6">
    <Pagination :pagination="pagination" @paginate:goto="updatePage">
      <div class="table-container">
        <table
          class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth has-text-left"
        >
          <thead>
            <tr>
              <th>Aksi</th>
              <th>Provider</th>
              <th>Gambar</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Aksi</th>
              <th>Provider</th>
              <th>Gambar</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="(data, index) in tableData" :key="index">
              <td class="has-text-centered">
                <button class="button is-success mr-1" @click="doEdit(data)">
                  Edit
                </button>
                <button class="button is-danger" @click="doDelete(data)">
                  Hapus
                </button>
              </td>
              <td>{{data.provider}}</td>
              <td>{{data.icon}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Pagination>
  </div>
</div>
